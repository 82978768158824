<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
      </b-row>
      <account-plan-submenu active="by-name"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <b-row no-gutters>
          <b-col class="col-md-12 pl-4">
            <h2 class="text-gray">{{ $t( translationPath + 'title_all_accounts') }}</h2>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col cols="12" class="pl-4">
            <p class="text-gray">{{ $t( translationPath + 'table_description') }}</p>
          </b-col>
        </b-row>

        <b-row no-gutters>
          <b-col cols="12" class="pl-4">

            <b-card no-body style="border: none;">
              <b-table class="spirecta-simple-table income-accounts-table editableAccounts" show-empty hover responsive
                       striped
                       :items="rawData"
                       :fields="fieldValues"
                       :filter="accountsFilter"
                       :filter-included-fields="['title','code','type','account_group']"
                       stacked="md"
                       :emptyText="$t('common.no_data')"
                       :busy="busy"
                       @row-hovered="rowHoveredHandler"
                       @row-unhovered="rowUnhoveredHandler"
              >

                <template v-slot:table-busy>
                  <loader/>
                </template>

                <!-- TOP ROW -->
                <template slot="top-row">
                  <td colspan="5">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-search text-secondary"></i>
                      <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                    </div>
                  </td>
                </template>

                <!-- CODE -->
                <template v-slot:cell(code)="row">
                  <template v-if="enableEditIndex.indexOf(row.item.id) === -1">
                    <template>
                      <b-link @click="enableEditing(row)">
                        <i class="flaticon mr-1 solid edit-3 text-primary"></i>
                      </b-link>
                    </template>
                    {{ row.value }}
                  </template>
                  <template v-else>
                    <b-form-group
                      class="accountCode"
                      :state="errors.code.indexOf(row.item.id) === -1"
                      :invalid-feedback="'Code is not unique.'"
                    >
                      <b-form-input v-model="row.item.code" class="transparentInput"
                                    :state="errors.code.indexOf(row.item.id) === -1"
                                    @update="() => validate(row, 'code')"/>
                    </b-form-group>
                  </template>
                </template>

                <!-- TITLE -->
                <template v-slot:cell(title)="row">
                  <template v-if="enableEditIndex.indexOf(row.item.id) === -1">
                    <template v-if="(row.item.type === 'income')">
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.item.title }}</b-link>
                    </template>
                    <template v-else-if="row.item.type === 'expense'">
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.item.title }}</b-link>
                    </template>
                    <template v-else-if="row.item.type === 'asset'">
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.item.title }}</b-link>
                    </template>
                    <template v-else-if="row.item.type === 'liability'">
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'">{{ row.item.title }}</b-link>
                    </template>
                    <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help" :title="$t(translationPath + 'badge.suspended_help')">{{ $t(translationPath + "badge.suspended") }}</b-badge>
                    <b-badge v-if="row.item.is_importable" variant="info" class="ml-2">{{ $t( translationPath +'badge.is_importable') }}</b-badge>
                    <b-badge v-if="row.item.is_capital_account" variant="info" class="ml-2">{{ $t( translationPath +'badge.is_capital_account') }}</b-badge>
                  </template>
                  <template v-else>
                    <b-form-group
                      :state="errors.title.indexOf(row.item.id) === -1"
                      :invalid-feedback="'Title is required.'"
                    >
                      <b-form-input v-model="row.item.title" class="transparentInput"
                                    :state="errors.title.indexOf(row.item.id) === -1"
                                    @update="() => validate(row, 'title')"/>
                    </b-form-group>
                  </template>
                </template>

                <!-- ACCOUNT_GROUP -->
                <template v-slot:cell(account_group)="row">
                  <template v-if="enableEditIndex.indexOf(row.item.id) === -1">
                    {{ row.value.title}}
                  </template>
                  <template v-else>
                    <b-form-group
                      :state="errors.accountGroup.indexOf(row.item.id) === -1"
                      :invalid-feedback="'Account group is required.'"
                    >
                      <table-multiselect-custom-view :onlyAccountGroup="true" :placeHolder="$t('common.select_account').toString()"
                                                     :accounttype="row.item.type" selectedType="id"
                                                     :selectedValue="row.item.account_group.id"
                                                     @account-selected="(value)=>accountChanged(value, row.item.id)"></table-multiselect-custom-view>
                    </b-form-group>
                  </template>
                </template>

                <!-- TYPE -->
                <template v-slot:cell(type)="row">
                  {{ $t('common.' + row.item.type) }}
                </template>

                <!-- HEAD:ACTIONS -->
                <template v-slot:head(actions)>&nbsp;
                </template>

                <!-- ACTIONS -->
                <template v-slot:cell(actions)="row">
                  <template v-if="enableEditIndex.indexOf(row.item.id) === -1">

                    <!--<b-link class='btn plain-btn text-regular action-button' :to="'/budget/account/' + row.item.id"
                            v-if="row.item.id !== networthAccount">
                      <i class="flaticon solid calculator-1 text-secondary"></i> <span>{{ $t('common.budget')}}</span>
                    </b-link>-->
                    <!--<b-link class='btn plain-btn text-regular action-button'
                            :to="'/reports/performance/accounts/' + row.item.id + '/view'"
                            v-if="row.item.id !== networthAccount">
                      <i class="flaticon stroke graph-1 text-secondary"></i> {{ $t('common.report') }}
                    </b-link>-->
                    <button class='btn plain-btn text-regular action-button' @click="onDeleteAccount(row.item)"
                            v-if="row.item.id !== networthAccount">
                      <i class="flaticon solid trash-3 text-secondary"></i> {{ $t('common.delete') }}
                    </button>
                    <b-link class='btn plain-btn text-regular action-button'
                            :to="['income', 'expense'].indexOf(row.item.type) > -1 ? '/incomes-expenses/accounts/' + row.item.type  + '/' + row.item.id + '/edit' : '/assets-liabilities/accounts/' + row.item.type  + '/' + row.item.id + '/edit'">
                      <i class="flaticon solid right-circle-2 text-primary"></i> {{ $t('common.edit') }}
                    </b-link>
                  </template>
                  <template v-else>
                    <button class='btn plain-btn text-regular action-button editButton' @click="cancelEditing(row)">
                      <i class="fa fa-times text-warning"></i> {{ $t('common.cancel') }}
                    </button>
                    <button class='btn plain-btn text-regular action-button cancelButton' @click="saveEditing(row)">
                      <i class="flaticon solid checkmark text-primary"></i> {{ $t('common.save') }}
                    </button>
                  </template>
                </template>
              </b-table>
            </b-card>

          </b-col>
        </b-row>
      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <delete-networth-account-modal ref="deleteModalNetworth"></delete-networth-account-modal>

    <delete-account-modal
      ref="deleteModal"
      :itemName="deleteAccountName"
      :itemType="deleteAccountType"
      :moveTransactions="onDeleteMoveTransactions"
      @on-delete-confirm="deleteAccount"
      @hide="resetModal"
    ></delete-account-modal>

    <delete-account-group-modal
      ref="deleteAccountgroupModal"
      :itemName="deleteAccountName"
      @on-delete-confirm="deleteAccountGroup"
      @hide="resetModal"
    ></delete-account-group-modal>

    <account-group-prevent-delete
      ref="deleteAccountGroupPreventModal"
      :itemName="deleteAccountName"
      @hide="resetModal"
    />

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import _ from 'lodash'
import TableMultiselectCustomView from '@/components/common/TableMultiselectCustomView'

export default {
  name: 'AccountPlanByName',
  components: { TableMultiselectCustomView },
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  data () {
    return {
      enableEditIndex: [],
      editRowData: [],
      rawData: [],
      hoveredRow: null,
      errors: {
        title: [],
        code: [],
        uniqueTitle: [],
        accountGroup: []
      },
      accountsFilter: ''
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'code', label: this.$t(this.translationPath + 'by-name.table.th_code'), sortable: true },
        { key: 'title', label: this.$t(this.translationPath + 'by-name.table.th_title'), class: 'text-left', sortable: true },
        { key: 'type', label: this.$t(this.translationPath + 'by-name.table.th_type'), sortable: true },
        { key: 'account_group', label: this.$t(this.translationPath + 'by-name.table.th_account_group'), class: 'text-left', sortable: true },
        { key: 'actions', label: this.$t(this.translationPath + 'by-name.table.th_actions'), class: 'd-lg-flex justify-content-lg-end align-items-center td-actions' }
      ]
    },
    networthAccount () {
      return this.currentCOA && Object.hasOwnProperty.call(this.currentCOA, 'networth_account_id') && this.currentCOA.networth_account_id ? this.currentCOA.networth_account_id : null
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccounts('income,expense,asset,liability')
      ])
        .then(() => {
          this.rawData = this.accounts
          this.busy = false
        })
    },
    rowHoveredHandler (item) {
      this.hoveredRow = item
    },
    rowUnhoveredHandler () {
      this.hoveredRow = null
    },
    onKeyDown (e) {
      /* Esc key handler */
      if (e.keyCode === 27) {
        if (this.hoveredRow !== null) {
          this.cancelEditing({ item: this.hoveredRow })
        } else {
          if (this.editRowData.length > 0) {
            this.cancelEditing({ item: this.editRowData[this.editRowData.length - 1] })
          }
        }
      }
      /* Enter key handler */
      if (e.keyCode === 13) {
        const ValidatedRows = this.rawData.filter(item => item.title && item.account_group).map(item => item.id)
        const filterValidatedIndex = this.editRowData.filter(item => ValidatedRows.indexOf(item.id) > -1)
        if (this.hoveredRow !== null && filterValidatedIndex.indexOf(this.hoveredRow) > -1) {
          this.saveEditing({ item: this.hoveredRow })
        } else {
          if (filterValidatedIndex.length > 0) {
            this.saveEditing({ item: filterValidatedIndex[filterValidatedIndex.length - 1] })
          }
        }
      }
    },
    clearErrors (id, type) {
      const enableReset = {
        title: true,
        code: true,
        uniqueTitle: true,
        group: true
      }
      if (type) {
        switch (type) {
          case 'title':
            enableReset.title = true
            enableReset.uniqueTitle = true
            enableReset.code = false
            enableReset.group = false
            break
          case 'code':
            enableReset.title = false
            enableReset.code = true
            enableReset.group = false
            break
          case 'group':
            enableReset.title = false
            enableReset.code = false
            enableReset.group = true
            break
          default:
            enableReset.title = true
            enableReset.uniqueTitle = true
            enableReset.code = true
            enableReset.group = true
        }
      }
      const titleErrorIndex = this.errors.title.indexOf(id)
      const uniqueTitleErrorIndex = this.errors.uniqueTitle.indexOf(id)
      const codeErrorIndex = this.errors.code.indexOf(id)
      const accountGroupErrorIndex = this.errors.accountGroup.indexOf(id)
      if (titleErrorIndex > -1 && enableReset.title) {
        this.errors.title.splice(titleErrorIndex, 1)
      }
      if (uniqueTitleErrorIndex > -1 && enableReset.uniqueTitle) {
        this.errors.uniqueTitle.splice(uniqueTitleErrorIndex, 1)
      }
      if (codeErrorIndex > -1 && enableReset.code) {
        this.errors.code.splice(codeErrorIndex, 1)
      }
      if (accountGroupErrorIndex > -1 && enableReset.group) {
        this.errors.accountGroup.splice(accountGroupErrorIndex, 1)
      }
    },
    validate (row, type) {
      if (type === 'title') {
        const nameIndex = this.errors.title.indexOf(row.item.id)
        if (row.item.title && row.item.title !== '') {
          this.clearErrors(row.item.id, 'title')
        } else {
          if (nameIndex === -1) {
            this.errors.title.push(row.item.id)
          }
        }
      } else if (type === 'account_group') {
        const accountIndex = this.errors.accountGroup.indexOf(row.item.id)
        if (row.item.account_group && row.item.account_group !== '') {
          this.clearErrors(row.item.id, 'group')
        } else {
          if (accountIndex === -1) {
            this.errors.accountGroup.push(row.item.id)
          }
        }
      } else {
        this.clearErrors(row.item.id, 'code')
      }
    },
    accountChanged (value, index) {
      this.rawData = this.rawData.map(item => {
        if (item.id === index) {
          return Object.assign(item, { account_group: value.accounts })
        } else {
          return item
        }
      })
    },
    enableEditing (row) {
      this.editRowData.push(_.cloneDeep(row.item))
      this.enableEditIndex.push(row.item.id)
    },
    cancelEditing (row) {
      const enableEditIndexId = this.enableEditIndex.indexOf(row.item.id)
      if (enableEditIndexId > -1) {
        this.enableEditIndex.splice(enableEditIndexId, 1)
        const findIndexRow = _.findIndex(this.editRowData, function (item) { return item.id === row.item.id })
        if (findIndexRow > -1) {
          const updateData = this.accounts.map(item => {
            if (item.id === this.editRowData[findIndexRow].id) {
              return Object.assign(item, this.editRowData[findIndexRow])
            } else {
              return item
            }
          })
          this.clearErrors(row.item.id)
          this.$emit('cancel-editing', updateData)
          this.editRowData.splice(findIndexRow, 1)
        }
      }
    },
    saveEditing (row) {
      const rowItem = row.item
      const postData = {
        code: rowItem.code,
        type: rowItem.type,
        title: rowItem.title,
        group_id: rowItem.account_group.id
      }
      this.validate(row, 'title')
      this.validate(row, 'group')
      if (this.errors.title.indexOf(rowItem.id) === -1 && this.errors.accountGroup.indexOf(rowItem.id) === -1) {
        axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/${rowItem.id}`, postData)
          .then(() => {
            const findIndexRow = _.findIndex(this.editRowData, function (item) { return item.id === rowItem.id })
            const enableEditIndexId = this.enableEditIndex.indexOf(row.item.id)
            if (enableEditIndexId > -1) {
              this.enableEditIndex.splice(enableEditIndexId, 1)
            }
            if (findIndexRow > -1) {
              this.editRowData.splice(findIndexRow, 1)
            }
            this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_category').toString(), {
              title: this.$t(this.translationPath + 'alert_update_category_title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            this.hasUnsavedChanges = false
          })
          .catch(err => {
            if (err.response.status === 422 && typeof (err.response.data.error.data.code) !== 'undefined') {
              this.$bvToast.toast(this.$t(this.translationPath + 'code_already_taken').toString(), {
                title: this.$t(this.translationPath + 'code_already_taken_title').toString(),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              })
              const uniqueCodeIndexes = this.errors.code.indexOf(row.item.id)
              if (uniqueCodeIndexes === -1) {
                this.errors.code.push(row.item.id)
              }
            } else if (err.response.status === 422 && typeof (err.response.data.error.data.title) !== 'undefined') {
              this.$bvToast.toast(this.$t(this.translationPath + 'title_already_taken').toString(), {
                title: this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'alert_del_account_title').toString() : this.$t(this.translationPath + 'alert_del_category_title').toString(),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              })
              const TitleUniqueIndexes = this.errors.uniqueTitle.indexOf(row.item.id)
              if (TitleUniqueIndexes === -1) {
                this.errors.code.push(row.item.id)
              }
            } else {
              this.$bvToast.toast(this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'alert_failed_to_update_account').toString() : this.$t(this.translationPath + 'alert_failed_to_update_category').toString(), {
                title: this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'alert_del_account_title').toString() : this.$t(this.translationPath + 'alert_del_category_title').toString(),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              })
              this.cancelEditing(row)
            }
          })
      }
    }
  },
  created () {
    this.loadData()
    this.onKeyDown = this.onKeyDown.bind(this)
  },
  destroyed () {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    accounts: function (newVal) {
      if (newVal) {
        this.rawData = newVal
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
<style lang="scss">
.editableAccounts {
  .editButton {
    margin: 10px 0;
  }
  .cancelButton {
    margin: 10px 0;
  }
  .form-group {
    position: relative;
    margin: 10px 0;
    .invalid-feedback {
      position: absolute;
      margin-top: 0;
      bottom: -16px;
      text-align: left;
      font-weight: 300;
    }
  }
  .accountCode {
    input {
      &.form-control {
        @media screen and (min-width: 769px) {
          max-width: 135px;
        }
      }
    }
  }
  #emberSelect {
    .ember-input {
      background: transparent;
      padding: 5px 40px 5px 0;
      border: none;
      min-height: auto;
      border-bottom: 1px solid #3bb37d;
      border-radius: 0;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        font-size: 16px;
      }
      &.active {
        &::after{
          background: transparent;
          padding: 12px 15px 5px 15px;
        }
      }
      &.inactive {
        &::after{
          background: transparent;
          padding: 4px 15px 10px 15px;
        }
      }
    }
  }
  input {
    &.transparentInput {
      padding: 5px 0 5px;
      height: auto;
      border: none;
      border-bottom: 1px solid #3bb37d;
      background: transparent;
      border-radius: 0;
      font-size: 16px;
    }
  }
}
</style>
