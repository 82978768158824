<template>
    <custom-select
        v-if="!onlyAccountGroup"
        v-model="option_value"
        :options="options"
        grouping-values="accounts"
        grouping-label="title"
        :label="'title'"
        :loading="loadingAccounts"
        @select="optionSelected"
        @open="openHandler"
        :disabled="disabled"
        :placeholder="placeHolder"
        :search-placeholder="searchPlaceholder"
        :noResultText="noResultText"
    >
    </custom-select>
    <custom-select
        v-else
        v-model="option_value"
        :options="options"
        :label="'title'"
        :loading="loadingAccounts"
        @select="optionSelected"
        @open="openHandler"
        :disabled="disabled"
        :placeholder="placeHolder"
        :search-placeholder="searchPlaceholder"
        :noResultText="noResultText"
    >
    </custom-select>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import CustomSelect from '@/components/common/CustomSelect'
export default {
  name: 'TableMultiselectCustomView',
  props: {
    accounttype: {
      type: String,
      default: 'asset'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectedType: {
      type: String,
      default: null
    },
    onlyAccountGroup: {
      type: Boolean,
      default: false
    },
    noResultText: {
      type: String,
      default: 'No result found'
    },
    value: {
      type: null,
      default () {
        return null
      }
    },
    selectedValue: {
      type: null,
      default () {
        return null
      }
    },
    placeHolder: {
      type: String,
      default: 'Select option'
    },
    searchPlaceholder: {
      type: String,
      default: 'Type to search'
    }
  },
  components: { CustomSelect },
  data () {
    return {
      options: [],
      transaction_type: null,
      loadingAccounts: false,
      option_value: null,
      enableMultipleAccount: false
    }
  },
  created () {
    if (this.accounttype) {
      this.transaction_type = this.accounttype
      this.fetchAccounts().then(data => {
        if (!this.onlyAccountGroup) {
          const filterOptions = this.options.filter(item => item.accounts).map(items => items.accounts)
          const accountsArray = _.flattenDeep(filterOptions)
          const filterAccountsArray = accountsArray.filter(item => this.selectedValue === item.id)
          this.option_value = filterAccountsArray.length > 0 ? filterAccountsArray[0] : null
        } else {
          const filterAccountGroups = this.options.filter(item => item.id === this.selectedValue)
          this.option_value = filterAccountGroups.length > 0 ? filterAccountGroups[0] : null
        }
        this.loadingAccounts = false
      }).catch(err => {
        console.error(err)
        this.loadingAccounts = false
      })
    }
  },
  methods: {
    async fetchAccounts () {
      this.loadingAccounts = true
      let AccountType = null
      if (this.transaction_type) {
        if (!this.onlyAccountGroup) {
          switch (this.transaction_type) {
            case 'income':
              AccountType = 'income'
              break
            case 'expense':
              AccountType = 'expense'
              break
            default:
              AccountType = 'asset,liability'
          }
        } else {
          switch (this.transaction_type) {
            case 'income':
              AccountType = 'income'
              break
            case 'expense':
              AccountType = 'expense'
              break
            case 'asset':
              AccountType = 'asset'
              break
            case 'liability':
              AccountType = 'liability'
              break
            default:
              AccountType = 'asset,liability'
          }
        }
        if (!this.onlyAccountGroup) {
          return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=TableMultiSelectCustomView&include_networth_account=1`)
            .then(response => {
              const optionArray = []
              const accountGroups = response.data.data
              if (['asset', 'liability'].indexOf(this.transaction_type) > -1) {
                Object.keys(accountGroups).forEach(function (key) {
                  if (this.transaction_type === accountGroups[key].type) {
                    optionArray.push({ title: accountGroups[key].title, accounts: accountGroups[key].accounts })
                  }
                }.bind(this))
                this.options = optionArray
                return this.options
              } else {
                Object.keys(accountGroups).forEach(function (key) {
                  optionArray.push({ title: accountGroups[key].title, accounts: accountGroups[key].accounts })
                })
                this.options = optionArray
                return this.options
              }
            })
            .catch(error => {
              console.error(error)
              this.options = []
              return []
            })
        } else {
          return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${AccountType}`)
            .then(response => response.data.data)
            .then(responseData => {
              this.options = responseData
              return this.options
            })
            .catch(err => {
              console.error(err)
              this.options = []
              return this.options
            })
        }
      }
    },
    optionSelected (selectedVal) {
      if (!this.onlyAccountGroup) {
        const filterOptions = this.options.map(item => {
          return item.accounts.map(items => {
            return Object.assign({}, items, { group_title: item.title })
          })
        })
        const selectedGroup = _.flattenDeep(filterOptions)
        const filterSelectedGroup = selectedGroup.filter(item => item.id === selectedVal.id)
        const accountGroup = filterSelectedGroup.length > 0 ? filterSelectedGroup[0].group_title : null
        this.$emit('account-selected', { accounts: selectedVal, selected_group: accountGroup })
      } else {
        this.$emit('account-selected', { accounts: selectedVal })
      }
    },
    removeSelected (selectedVal) {
      this.$emit('account-remove')
    },
    openHandler () {
      if (!this.transaction_type && this.accounttype && this.options.length === 0) {
        this.transaction_type = this.accounttype
        this.fetchAccounts().then(data => {
          if (!this.onlyAccountGroup) {
            const filterOptions = this.options.filter(item => item.accounts).map(items => items.accounts)
            const accountsArray = _.flattenDeep(filterOptions)
            const filterAccountsArray = accountsArray.filter(item => this.selectedValue === item.id)
            this.option_value = filterAccountsArray.length > 0 ? filterAccountsArray[0] : null
          } else {
            const filterAccountGroups = this.options.filter(item => item.id === this.selectedValue)
            this.option_value = filterAccountGroups.length > 0 ? filterAccountGroups[0] : null
          }
          this.loadingAccounts = false
        }).catch(err => {
          console.error(err)
          this.loadingAccounts = false
        })
      }
    }
  },
  watch: {
    accounttype: function (newVal, oldVal) {
      if (newVal) {
        this.transaction_type = newVal
        this.fetchAccounts().then(data => {
          this.loadingAccounts = false
          this.option_value = null
        }).catch(err => {
          console.error(err)
          this.loadingAccounts = false
          this.option_value = null
        })
      }
    },
    selectedValue: function (newVal, oldVal) {
      if (!this.onlyAccountGroup) {
        const filterOptions = this.options.filter(item => item.accounts).map(items => items.accounts)
        const accountsArray = _.flattenDeep(filterOptions)
        const filterAccountsArray = accountsArray.filter(item => newVal === item.id)
        this.option_value = filterAccountsArray.length > 0 ? filterAccountsArray[0] : null
      } else {
        const filterAccountGroups = this.options.filter(item => item.id === this.selectedValue)
        this.option_value = filterAccountGroups.length > 0 ? filterAccountGroups[0] : null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tableMultiselect {
  .multiselect__placeholder {
    margin-bottom: 6px;
  }
  /* option {
    background: #fff;
    color: #000;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 100;
  }
  optgroup {
    background: #ededed;
    color: #888787;
    font-size: 17px;
    padding: 15px;
    font-weight: 100;
  } */
}
</style>
